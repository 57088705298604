<template>
    <div class="bg-white p-5" style="border: solid 1px grey;">
        <div>
            <h4>Bài tập về nhà buổi {{ lesson }}</h4>
        </div>
        <hr/>
        <div v-loading="loadingMarkHomework" class="modal-fillter-poin table">
            <div class="table-responsive overflow-auto mt-3">
                <table :aria-busy="isBusy" class="table" style="text-align: center;">
                    <thead>
                        <tr>
                            <th scope="col" class="min-w-100px">Họ và tên</th>
                            <th scope="col" class="min-w-100px">Mã học viên</th>
                            <th scope="col" class="min-w-100px">Số điện thoại</th>
                            <th scope="col" class="min-w-100px">Trạng thái</th>
                            <th scope="col" class="min-w-120px">Hình thức chấm</th>
                            <th scope="col" class="text-center">Thời gian GV chấm bài</th>
                            <th scope="col" class="min-w-120px">Hành động</th>
                        </tr>
                    </thead>
                    <tbody>
                        <HomeWork v-for="(item, index) in homeWorkList" :learning_schedule_id="learning_schedule_id"
                            :lessonNumber="lesson" @action-mark-homework="actionMarkHomework" :homeWork="item"
                            :key="index"></HomeWork>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import HomeWork from './components/HomeWork.vue';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {ADMIN_HOMEWORK} from "@/core/services/store/course/classes.module";

export default {
    components: {
        HomeWork,
    },
    data() {
        return {
            lesson: null,
            classroom_id: null,
            learning_schedule_id: null,
            homeWorkList:[],
            loadingMarkHomework: false,
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Chi tiết lớp học", route: 'schedule-class' },
            { title: "Danh sách lớp học" }
        ]);
        this.lesson = this.$route.query.lesson
        this.classroom_id = this.$route.query.classroom_id
        this.learning_schedule_id = this.$route.query.learning_schedule_id
        this.getListHomework()
    },
    methods: {
        getListHomework() {
            this.$store.dispatch(ADMIN_HOMEWORK, {classroom_id:this.classroom_id,lesson:this.lesson,type:'homework'}).then((res) => {
                if (!res.error) {
                    this.homeWorkList = res.data;
                    this.loadingMarkHomework = false;
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        }
    },
}
</script>

<style lang="scss" scoped></style>